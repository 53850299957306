<template>
  <div class="registered_page">
    <h1>货主注册</h1>
    <el-form ref="form" label-width="150px" :rules="rules" :model="formData">
      <el-row>
        <el-col class="page_title" :span="24">
          基本信息
        </el-col>
        <el-col :span="8">
          <el-form-item  label="姓名" >
            <el-input v-model="formData.xingming" placeholder="姓名"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="手机号" >
            <el-input v-model="formData.shoujihao"  placeholder="手机号"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="身份证号" >
            <el-input  v-model="formData.shenfenzhenghao" placeholder="身份证号"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item  label="货主类型" >
            <el-select class="select" v-model="formData.huozhuuleixing" placeholder="请选择货主类型">
              <el-option
                  v-for="item in huozhuleixing"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="16">
          <el-form-item label="上传身份证">

            <el-upload
                class="upload-demo"
                ref="upload"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :file-list="fileList"
                list-type="picture"
                :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            </el-upload>
<!--            <el-upload-->
<!--                class="upload-demo"-->
<!--                :limit="1"-->
<!--                action="https://jsonplaceholder.typicode.com/posts/"-->
<!--                :on-preview="handlePreview"-->
<!--                :on-remove="handleRemove"-->
<!--                :file-list="fileList"-->
<!--                list-type="picture"-->
<!--            >-->
<!--              <el-button size="small" type="primary">点击上传</el-button>-->
<!--            </el-upload>-->
          </el-form-item>
        </el-col>
      </el-row>

      <el-divider/>


      <el-row>
        <el-col class="page_title"  :span="24">
          企业类型请补充
        </el-col>
        <el-col :span="8">
          <el-form-item label="企业名称"  >
            <el-input placeholder="企业名称" v-model="formData.qiyemingcheng"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="注册地址"  >
            <el-input placeholder="注册地址" v-model="formData.zhucedizhi"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="统一社会信用代码" >
            <el-input placeholder="统一社会信用代码"  v-model="formData.tongyidaima"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="注册资本（万元）"  >
            <el-input placeholder="注册资本（万元）" v-model="formData.zhuceziben"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="成立日期"  >
            <el-date-picker v-model="formData.chengliriqi " class="select" type="date" placeholder="选择日期"/>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="营业期限" >
            <el-select class="select" v-model="formData.yingyeqixian" placeholder="请选择营业期限">
              <el-option
                  v-for="item in yingyeqixianoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="营业起始日期" >
            <el-date-picker v-model="formData.yingyeqishiriqi" class="select" type="date" placeholder="选择日期"/>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="公司性质" >
            <el-select class="select" v-model="formData.gongsixingzhi" placeholder="请选择公司性质">

              <el-option
                  v-for="item in gongsixingzhioptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="16">
          <el-form-item label="上传营业执照" >

            <el-upload
                class="upload-demo"
                ref="upload"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :file-list="shangchuanfileList"
                list-type="picture"
                :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>


      <el-divider/>


      <el-row>
        <el-col class="page_title" :span="24">
          法人&联系人信息
        </el-col>
        <el-col :span="8">
          <el-form-item label="法人姓名" >
            <el-input placeholder="法人姓名" v-model="formData.farenxingming"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="法人手机号">
            <el-input placeholder="法人手机号"  v-model="formData.farenshoujihao"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="法人身份证号" >
            <el-input placeholder="法人身份证号"  v-model="formData.farenshenfenzheng"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-divider/>

      <div class="sign_card">
        <el-checkbox v-model="checked"></el-checkbox>
        <span style="font-size: 14px; padding-left: 20px; color: #3572b0;" @click="$router.push({name: 'agreement'})">《滴滴网络货运服务协议》</span>
      </div>




    </el-form>

    <div class="btn_box">
      <el-button @click="$router.push({name: 'Home'})">取消</el-button>

      <el-button type="primary" @click="submit">确认</el-button>
    </div>







    <el-dialog
        title="注册成功！"
        :visible.sync="dialogVisible"
        width="500px"
        :before-close="handleClose">
          <div class="qrcode_box">
            <qrcode :value="qrcodeUrl" v-if="qrcodeUrl" :options="{ size: 400 }"></qrcode>
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span>
    </el-dialog>


  </div>
</template>

<script>

import QRcode from '@xkeshi/vue-qrcode'
export default {
  name: 'registered_page',
  data () {
    return {
      formData: {

      },
      checked:false,
      rules: {
        required: {required: true, message: '请输入', trigger: 'change' }
      },
      fileList: [],
      shangchuanfileList:[],
      huozhuleixing: [
        {value: 1, label: '自然人'},
        {value: 2, label: '企业'}
      ],
      yingyeqixianoptions: [
        {value: 1, label: '一年'},
        {value: 2, label: '10年'},
        {value: 3, label: '20年'},
        {value: 4, label: '30年'},
        {value: 5, label: '长期'}
      ],
      gongsixingzhioptions: [
        {value: 1, label: '国有企业'},
        {value: 2, label: '集体企业'},
        {value: 3, label: '私营企业'},
        {value: 4, label: '个体工商户'},
        {value: 5, label: '合伙企业'},
        {value: 6, label: '联营企业'},
        {value: 7, label: '股份合作制企业'},
        {value: 8, label: '有限责任公司'},
        {value: 9, label: '股份有限公司'}
      ],
      dialogVisible: false,
      qrcodeUrl: 'https://z.didi.cn/freightApp',

    }
  },
  components :{
    qrcode : QRcode
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    submit(){
      // this.$refs.form.validate( valid => {
      //   console.log(valid)
      //   if(valid){
      //     console.log(1)
      //   }
      // })
      setTimeout(() => {
        this.$message.success("注册成功！")
        setTimeout(() => {
          this.dialogVisible = true
        },500)

      },800)

    },
    handleClose(){
      this.dialogVisible = false
    },
  }
}
</script>

<style scoped lang="scss">
.registered_page{
  width: 1200px;
  padding:40px  20px;

  .page_title{
    padding-bottom: 20px;
  }
  .select{
    width: 100%;
  }
  .btn_box{
    text-align: center;
  }
  .qrcode_box{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .sign_card{
    padding-bottom: 50px;
    width: 300px;
    text-align: center;
    margin: 0 auto;
  }
}
</style>
