<template>
  <div class="agreement_page">
    <img src="../assets/image/clipboard_image_1617009102989.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'agreement_page',
  data () {
    return {

    }
  },
  created () {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.agreement_page{
  text-align: center;
}
</style>
